const initBlock = async () => {
  const sections = Array.from(document.getElementsByClassName('b-homepage-offer'));

  for (const section of sections) {
    const servicesTabs = section.querySelector('.swiper[data-offer-tags="services"]');
    const solutionsTabs = section.querySelector('.swiper[data-offer-tags="solutions"]');
    const servicesContent = section.querySelector('.swiper[data-offer-content="services"]');
    const solutionsContent = section.querySelector('.swiper[data-offer-content="solutions"]');
    const serviceBtns = section.querySelectorAll('button[data-offer-tag-type="services"]');
    const solutionBtns = section.querySelectorAll('button[data-offer-tag-type="solutions"]');
    const switchBtns = section.querySelectorAll('button[data-offer-switch]');

    if (
      !servicesTabs ||
      !solutionsTabs ||
      !servicesContent ||
      !solutionsContent ||
      !serviceBtns ||
      !solutionBtns ||
      !switchBtns
    ) {
      continue;
    }

    const {Swiper} = await import('swiper');
    const {Controller, EffectFade, FreeMode, A11y} = await import('swiper/modules');

    const tabsConfig = {
      modules: [FreeMode, Controller, A11y],
      slidesPerView: 'auto',
      spaceBetween: 4,
      freeMode: {
        enabled: true,
      },
      focusableElements: '',
    };

    const contentsConfig = {
      modules: [EffectFade, A11y],
      autoHeight: true,
      slidesPerView: 1,
      spaceBetween: 16,
      effect: 'fade',
      draggable: false,
      clickable: false,
      slideToClickedSlide: false,
      preventClicks: true,
      preventClicksPropagation: true,
      allowTouchMove: false,
    };

    const servicesTabsSlider = new Swiper(servicesTabs, tabsConfig);
    const solutionsTabsSlider = new Swiper(solutionsTabs, tabsConfig);
    const servicesContentSlider = new Swiper(servicesContent, contentsConfig);
    const solutionsContentSlider = new Swiper(solutionsContent, contentsConfig);

    serviceBtns.forEach((btn) => {
      btn.addEventListener('click', (e) => {
        e.preventDefault();
        serviceBtns.forEach((btn) => btn.classList.remove('active'));
        const index = btn.getAttribute('data-offer-tag');
        servicesTabsSlider.slideTo(index);
        servicesContentSlider.slideTo(index);
        btn.classList.add('active');
      });
    });

    solutionBtns.forEach((btn) => {
      btn.addEventListener('click', (e) => {
        e.preventDefault();
        solutionBtns.forEach((btn) => btn.classList.remove('active'));
        const index = btn.getAttribute('data-offer-tag');
        solutionsTabsSlider.slideTo(index);
        solutionsContentSlider.slideTo(index);
        btn.classList.add('active');
      });
    });

    switchBtns &&
      switchBtns.forEach((btn) => {
        btn.addEventListener('click', (e) => {
          e.preventDefault();
          switchBtns.forEach((btn) => btn.classList.remove('btn--primary'));
          const tag = btn.getAttribute('data-offer-switch');

          if (tag === 'services') {
            servicesTabs.classList.add('active');
            solutionsTabs.classList.remove('active');
            servicesContent.classList.add('active');
            solutionsContent.classList.remove('active');
            servicesTabsSlider.update();
            servicesContentSlider.update();
          } else {
            servicesTabs.classList.remove('active');
            solutionsTabs.classList.add('active');
            servicesContent.classList.remove('active');
            solutionsContent.classList.add('active');
            solutionsTabsSlider.update();
            solutionsContentSlider.update();
          }

          btn.classList.add('btn--primary');
        });
      });
  }
};

window.requestAnimationFrame(async function check() {
  document.body ? await initBlock() : window.requestAnimationFrame(check);
});

if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
